<template>
  <BaseDialog
    :append-to-body="true"
    hideClose
    hideConfirm
    hideCancel
    @close="$emit('close')"
  >
    <div v-loading="loading" class="content-wrapper">
      <div id="grecaptcha" />
    </div>
  </BaseDialog>
</template>

<script>
import { get } from 'lodash'
import BaseDialog from './Dialog/BaseDialog.vue'
import { defineComponent, nextTick, onMounted, ref } from 'vue'
import store from '@/store'

export default defineComponent({
  name: 'GRecaptchaV2Modal',
  components: {
    BaseDialog,
  },
  setup (props, { emit }) {
    const loading = ref(true)
    const loaded = () => {
      const submit = (response) => {
        emit('verify', response)
        emit('close')
      }
      setTimeout(() => {
        loading.value = true
        window.grecaptcha.render('grecaptcha', {
          sitekey: get(store.state, 'app.siteKey.v2'),
          callback: submit,
        })
        loading.value = false
      }, 200)
    }

    onMounted(async () => {
      await nextTick()
      loaded()
    })

    return { loading }
  },
})

</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply bg-white flex justify-center pb-[45px] px-[50px]  rounded-[5px];
}
</style>
