<template>
  <div class="">
    <BaseAdavanceSelector
      :get-data-api="GetMembers"
      :get-data-count-api="GetMembersCount"
      :keyMap="{ id: 'id', name: 'UserInfo.name' }"
      multiple
    />

    <BaseElButton @click="toggle.recaptcha = !toggle.recaptcha">
      Toggle Recaptcha
    </BaseElButton>

    <BaseElButton @click="onRecaptchaV3">
      Recaptcha V3
    </BaseElButton>

    <GRecaptchaV2Modal
      v-if="toggle.recaptcha"
      @close="toggle.recaptcha = false"
      @verify="onRecaptchaVerify"
    />
  </div>
</template>

<script>
import BaseAdavanceSelector from '@/components/Select/BaseAdavanceSelector.vue'
import { GetMembers, GetMembersCount } from '@/api/member'

import { defineComponent, reactive } from 'vue'
import { apiFormatAdaptor } from '@/utils/api'
import GRecaptchaV2Modal from '@/components/GRecaptchaV2Modal.vue'
export default defineComponent({
  name: 'Playground',
  components: {
    BaseAdavanceSelector,
    GRecaptchaV2Modal,
  },
  setup (props) {
    const toggle = reactive({
      recaptcha: false,
    })

    const dataList = [
      { name: 'test1', id: 'type1' },
      { name: 'test2', id: 'type2' },
      { name: 'test3', id: 'type3' },
      { name: 'test4', id: 'type4' },
    ]

    const onRecaptchaVerify = (response) => {
      console.log('onRecaptchaVerify', response)
    }

    return {
      toggle,
      dataList,
      GetMembers: apiFormatAdaptor(GetMembers),
      GetMembersCount: apiFormatAdaptor(GetMembersCount),
      onRecaptchaVerify,
    }
  },
  methods: {
    async onRecaptchaV3 () {
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('test')
      console.log('onRecaptchaV3', token)
    },
  },
})
</script>

<style lang="postcss" scoped>

</style>
